import { Component, ComponentRef, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";

import { Constants } from "../../../../constants/constants";
import { ModalService } from "../../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";
//
import { AlertingProfile, AlertingRule } from "../events-management";
import { EventsManagementService } from "../events-management.service";
import { take } from "rxjs/operators";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ColumnFilterType } from "src/app/components/shared/filter/filter.component";
import { KeyMap } from "src/app/models/shared";
import { ViewAlertingRuleComponent } from "../view-alerting-rule/view-alerting-rule.component";

@Component({
    selector: "app-alerting-profile",
    templateUrl: "./alerting-profile.component.html"
})
export class AlertingProfileComponent implements OnDestroy {
    alertProfile: AlertingProfile;
    alertProfileID: number;
    private alertProfilesSubscription: Subscription;

    constants = Constants;
    alertRules: AlertingRule[];
    loadingDetails = true;

    get alert_rules$() {
        return this.alertRules$.asObservable();
    }

    alertRules$ = new BehaviorSubject<AlertingRule[]>([]);

    tableColumnsSchema: TableSchema[] = [
        {
            columnDef: "expand",
            header: "Expand",
            visible: true,
            sticky: 0,
            width: 32,
            expandDisabled: (row: KeyMap<AlertingRule>) => false,
            component: ViewAlertingRuleComponent,
            assignComponentsInputs: (
                compRef: ComponentRef<ViewAlertingRuleComponent>,
                row: KeyMap<AlertingRule>,
                searchTerm: string[]
            ) => {
                const ref = compRef.instance;
                const props = {
                    rule: row,
                    alertingProfileId: this.alertProfileID
                };
                for (const key in props) {
                    const value = props[key];
                    ref[key] = value;
                }
            }
        },
        {
            header: this.translate.instant("TYPE"),
            columnDef: "type",
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<AlertingRule>(
                row => this.translate.instant(row.object_type.toUpperCase()),
                row => this.translate.instant(row.object_type.toUpperCase()),
                () => true
            ),
            sortBy: (row: AlertingRule): string => this.translate.instant(row.object_type.toUpperCase()),
            textValue: (row: AlertingRule): string => this.translate.instant(row.object_type.toUpperCase()),
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("GROUP"),
            columnDef: "group",
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<AlertingRule>(
                row => this.translate.instant(row.group.toUpperCase()),
                row => this.translate.instant(row.group.toUpperCase()),
                () => true
            ),
            sortBy: (row: AlertingRule): string => this.translate.instant(row.group.toUpperCase()),
            textValue: (row: AlertingRule): string => this.translate.instant(row.group.toUpperCase()),
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("RULE"),
            columnDef: "rule",
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<AlertingRule>(
                row => this.translate.instant(row.code.toUpperCase()),
                row => this.translate.instant(row.code.toUpperCase()),
                () => true
            ),
            sortBy: (row: AlertingRule): string => this.translate.instant(row.code.toUpperCase()),
            textValue: (row: AlertingRule): string => this.translate.instant(row.code.toUpperCase()),
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("MODIFIED"),
            columnDef: "modified",
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<AlertingRule>(
                row => (row.alerting_profile_id ? this.translate.instant("YES") : this.translate.instant("NO")),
                row => (row.alerting_profile_id ? this.translate.instant("YES") : this.translate.instant("NO")),
                () => true
            ),
            sortBy: (row: AlertingRule): string =>
                row.alerting_profile_id ? this.translate.instant("YES") : this.translate.instant("NO"),
            textValue: (row: AlertingRule): string =>
                row.alerting_profile_id ? this.translate.instant("YES") : this.translate.instant("NO"),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: [this.translate.instant("YES"), this.translate.instant("NO")],
            columnFilterValue: (row: AlertingRule) =>
                row.alerting_profile_id ? this.translate.instant("YES") : this.translate.instant("NO")
        },
        {
            header: this.translate.instant("DISABLED"),
            columnDef: "disabled",
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<AlertingRule>(
                row => (row.disabled ? this.translate.instant("YES") : this.translate.instant("NO")),
                row => (row.disabled ? this.translate.instant("YES") : this.translate.instant("NO")),
                () => true
            ),
            sortBy: (row: AlertingRule): string =>
                row.disabled ? this.translate.instant("YES") : this.translate.instant("NO"),
            textValue: (row: AlertingRule): string =>
                row.disabled ? this.translate.instant("YES") : this.translate.instant("NO"),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: [this.translate.instant("YES"), this.translate.instant("NO")],
            columnFilterValue: (row: AlertingRule) =>
                row.disabled ? this.translate.instant("YES") : this.translate.instant("NO")
        }
    ];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private ems: EventsManagementService,
        private modalService: ModalService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService
    ) {
        this.route.paramMap.subscribe(params => {
            this.alertProfileID = +params.get("id");
            if (!this.alertProfileID) return this.cancel();

            this.loadingDetails = true;

            this.ems
                .getAlertingProfile(this.alertProfileID)
                .pipe(take(1))
                .subscribe(profile => {
                    if (!profile) return this.cancel();
                    this.alertProfile = this.ems.setProfileWithOrderedRules(profile);
                    this.alertRules$.next([...this.alertProfile.alertingRules]);

                    // Set Title
                    this.titleService.setTitle("ALERT_PROFILE", "", this.alertProfile);

                    this.loadingDetails = false;
                });
        });
    }

    ngOnDestroy() {
        this.alertProfilesSubscription?.unsubscribe();
    }

    cancel() {
        this.gotoAlertProfiles();
    }

    private gotoAlertProfiles() {
        this.router.navigate([Constants.urls.configuration.eventsManagement]);
    }

    async deleteAlertProfile() {
        await this.modalService.confirm(
            "DELETE",
            "ALERT_PROFILE",
            async () => {
                const id = this.alertProfile.id;
                const result = await this.ems.deleteAlertingProfile(this.alertProfile);
                if (result) {
                    this.mixpanelService.sendEvent("delete alert profile", { id });
                    this.gotoAlertProfiles();
                } else {
                    return false;
                }
            },
            this.alertProfile.name
        );
    }

    editAlertProfile(id: number): void {
        this.router.navigate([Constants.urls.configuration.eventsManagement, id, "edit"]);
    }

    cloneAlertProfile(id: number): void {
        this.router.navigate([Constants.urls.configuration.eventsManagement, id, "clone"]);
    }
}
